import React, { useState } from 'react';
import { Button, InputField } from '../../../Components';
import { findError } from '../../../Utils';
import Loader from '../../Loader';

export default function Login({ submitHandler, controls, errors, showErr, onChange, loading }) {
  const { phone_number, password } = controls;
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  return (
    <div className="flex flex-col gap-15">
      <div/>
      <InputField
        label={'Mobile Number'}
        type='number'
        placeholder='Mobile Number'
        value={phone_number}
        onChange={(e) => onChange(e, 'PHONE')}
        error={findError('phone_number', errors, showErr)}
      />

      <button
        className='btn-inactive w-full btn-lg'
        onClick={submitHandler}
      >
        {
          loading  ?
          <Loader /> : 
          'Login Via Otp'
        }
      </button>
    </div>
  );
}
