import React, { useEffect, useState } from 'react'
import '../Auth.scss';
import { Button, InputField } from '../../../Components';
import { findError } from '../../../Utils';
import AddressForm from '../../AddressForm';
import Loader from '../../Loader';
export default function Signup({ submitHandler, controls, errors, showErr, onChange, loading }) {
    const { phone_number, email, password, username, addressurl, coordinates, } = controls;
    const [signupAddress, setSignupAddress] = useState('')

    const addressSetter = (item, area, coords = { lat: "", lng: "" }) => {
        // setSignupAddress(item)
        let address = {
            'target': {
                'value': item
            }
        }
        let coordinates = {
            'target': {
                'value': coords
            }
        }
        let inventory = {
            'target': {
                'value': area
            }
        }
        onChange(address, 'ADDRESS');
        onChange(inventory, 'INVENTORY');
        onChange(coordinates, 'COORDINATES');

    }

    useEffect(() => {
    }, [signupAddress])

    return (
        <div className="flex flex-col gap-10">
            <div/>

            <InputField
                label={'Name'}
                type='text'
                placeholder='Name'
                value={username}
                onChange={(e) =>
                    onChange(e, 'USERNAME')}
                error={findError('username', errors, showErr)}
            />

            <InputField
                label={"Email"}
                type="email"
                placeholder='E-mail'
                value={email}
                onChange={(e) =>
                    onChange(e, 'EMAIL')}
                error={findError('email', errors, showErr)}
            />

            <InputField
                label={'Mobile Number'}
                type='number'
                placeholder='Mobile Number'
                value={phone_number}
                onChange={(e) =>
                    onChange(e, 'PHONE')}
                error={findError('phone_number', errors, showErr)}
            />

            <AddressForm 
                labels='Address'
                showButton={false}
                isSignup={true}
                addressSetter={addressSetter}
            />

            <InputField 
                label={'Password'}
                type="password"
                placeholder='Password'
                value={password}
                onChange={(e) =>
                    onChange(e, 'PASSWORD')}
                error={findError('password', errors, showErr)}
            />

            <button
                className='btn-inactive w-full btn-lg'
                onClick={submitHandler}
            >
                {
                    loading  ?
                    <Loader /> : 
                    'Register'
                }
            </button> 
        </div>
    )
}