// import Nav from '../../Components/Nav/Nav'
import Footer from '../../Components/Footer/Footer'
// import Loader from '../../Components/Loader/Loader'
import AddWastedProduct from './AddWastedproduct'

export default function WastedProduct() {
    return (
        <>
            {/* <Loader /> */}
            <div className="container-fluid p-3">

                {/* <Nav /> */}
                <div className="container">
                    <AddWastedProduct />
                </div>


            </div>
        </>
    )
}
