import React, { useState, useEffect } from 'react'
import './Products.scss'

import starIconFilled from '../../Assets/Icons/star-icon.svg'
import starIconOutline from '../../Assets/Icons/star-icon-outline.svg'
import ProductImage1 from '../../Assets/Images/Product1.png'
import ProductImage2 from '../../Assets/Images/Product2.png'
import ProductImage3 from '../../Assets/Images/Product3.png'
import ProductImage4 from '../../Assets/Images/Product4.png'

import axios from 'axios'

import { productAPI } from "../../Api";

import ProductCard from '../../Components/ProductCard/ProductCard.js'

import Button from '../../Components/Homepage/Button/Button.js'

import { AddToCartButton } from '../../Components'

const ProductsSlider = () => {

    // const [data, setdata] = useState([]);
    // const [tab, settab] = useState("Snacks");

    // const fetchData = () => {
    //     axios
    //         .get("https://phurti.in/api/shop/products/" + tab + "/")
    //         .then((response) => {
    //             console.log(response);
    //             setdata(response.data.data);
    //         })
    //         .catch((err) => console.log(err));
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    const productCardArray = Array.from({ length: 15 }, () => 0);

    return (
        <div className='product-content-container'>
            <div className='product-content-wrapper'>
                { 
                  productCardArray.map( (item, index) => {
                        return (
                            <ProductCard
                                id={`product${index}`}
                                image={
                                    (index % 4 === 0 && ProductImage1) ||
                                    (index % 4 === 1 && ProductImage2) ||
                                    (index % 4 === 2 && ProductImage3) ||
                                    (index % 4 === 3 && ProductImage4)
                                }
                                title={'Red Velvet & Cream Cheese ( 500g )'}
                                oldprice={300}
                                price={500}
                                rating={4}
                            />
                        );
                    })
                }
            </div>
        </div>
    )
}

const AllProduct = () => {

    const productCardArray = Array.from({ length: 15 }, () => 0);

    return (
        <div className='allproduct-content-container'>
            <div className='allproduct-content-wrapper'>
                {
                    productCardArray.map((value, index) => {
                        return (
                            <ProductCard
                                id={`product${index}`}
                                imageSrc={
                                    (index % 4 === 0 && ProductImage1) ||
                                    (index % 4 === 1 && ProductImage2) ||
                                    (index % 4 === 2 && ProductImage3) ||
                                    (index % 4 === 3 && ProductImage4)
                                }
                                productTitle={'Red Velvet & Cream Cheese ( 500g )'}
                                rating={4}
                            />
                        )
                    })
                }
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem'
            }}>
                <Button
                    text={'Load More'}
                    border={'1px solid #4a1d1e'}
                    textcolor={'#272727'}
                />
            </div>
        </div>
    )
}

export { AllProduct }

export default ProductsSlider