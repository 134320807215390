import React, { useEffect, useState } from "react";
import Invoiceslip from "./Invoiceslip";
import "./Invoice.scss";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import InvoicePdfTemplate from "./InvoicePdfTemplate";
import Button from "../../Components/Homepage/Button/Button";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { dashboardAPI } from "../../Api";
import { toast } from "react-toastify";
import { cartAPI } from "../../Api";
import Loader from "../../Components/Loader";
import get from "lodash/get";
import { fetchnotes } from "../../Api/cartAPI";

// Define an object that maps numbers to their word form

// Define the convertNumberToWords function
export function number2text(value) {
  var fraction = Math.round(frac(value)*100);
  var f_text  = "";

  if(fraction > 0) {
      f_text = "AND "+convert_number(fraction)+" PAISE";
  }

  return convert_number(value)+" RUPEES "+f_text;
}

function frac(f) {
  return f % 1;
}

function convert_number(number)
{
  if ((number < 0) || (number > 999999999)) 
  { 
      return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000);  /* Crore */ 
  number -= Gn * 10000000; 
  var kn = Math.floor(number / 100000);     /* lakhs */ 
  number -= kn * 100000; 
  var Hn = Math.floor(number / 1000);      /* thousand */ 
  number -= Hn * 1000; 
  var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
  number = number % 100;               /* Ones */ 
  var tn= Math.floor(number / 10); 
  var one=Math.floor(number % 10); 
  var res = ""; 

  if (Gn>0) 
  { 
      res += (convert_number(Gn) + " CRORE"); 
  } 
  if (kn>0) 
  { 
          res += (((res=="") ? "" : " ") + 
          convert_number(kn) + " LAKH"); 
  } 
  if (Hn>0) 
  { 
      res += (((res=="") ? "" : " ") +
          convert_number(Hn) + " THOUSAND"); 
  } 

  if (Dn) 
  { 
      res += (((res=="") ? "" : " ") + 
          convert_number(Dn) + " HUNDRED"); 
  } 


  var ones = Array("", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX","SEVEN", "EIGHT", "NINE", "TEN", "ELEVEN", "TWELVE", "THIRTEEN","FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN","NINETEEN"); 
var tens = Array("", "", "TWENTY", "THIRTY", "FOURTY", "FIFTY", "SIXTY","SEVENTY", "EIGHTY", "NINETY"); 

  if (tn>0 || one>0) 
  { 
      if (!(res=="")) 
      { 
          res += " AND "; 
      } 
      if (tn < 2) 
      { 
          res += ones[tn * 10 + one]; 
      } 
      else 
      { 

          res += tens[tn];
          if (one>0) 
          { 
              res += ("-" + ones[one]); 
          } 
      } 
  }

  if (res=="")
  { 
      res = "zero"; 
  } 
  return res;
}

function convertTimestamp(timestamp) {
  // Create a Date object from the timestamp
  const date = new Date(timestamp);

  // Get the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear();

  // Format the date as dd-mm-yyyy
  return `${day}-${month}-${year}`;
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const mapStateToProps = ({ auth }) => ({
  auth,
});

const Notes = () => {
  const { auth } = useSelector(mapStateToProps);
  // const { orderid } = useParams();
  // const challanId = Number(orderid);
  const { orderid } = useParams();
  const invoiceId = Number(orderid);
  const [orderDetails, setOrderDetails] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [invoice_status, setInvoiceStatus] = useState("CREATED");
  const [ticket, SetTicket] = useState(null);
  const [filtered_data, setFilteredata] = useState(null);
  const [isProforma, setProforma] = useState(true);
  const [isInvoice,setisInvoice] = useState('')
  const [isDiscount, setIsDiscount] = useState(false)

  function customCeil(num) {
    const decimal = (num) % 1; // Get the decimal part
    const intPart = Math.floor(num); // Get the integer part
  
    if (decimal >= 0.5) {
      return Math.ceil(num ) // Ceil to 1 decimal place
    } else {
      return Math.floor(num) // Floor to 1 decimal place
    }
  }

  useEffect(async () => {
    const data = {
      id: invoiceId,
    };
    // const response = await dashboardAPI.fetchOrderDetails(data);
    // setOrderDetails(response.data);

    fetchInvoiceDetails();
  }, []);

  const fetchInvoiceDetails = async () => {
    try {
      const res = await fetchnotes(invoiceId);
      if (res.data.status === 400) {
        setInvoice({});
        toast.error("No invoice found");
      } else {
        const invoiceData = get(res, "data", {});

        setInvoice(invoiceData);
        if((invoiceData?.note?.applied_on)==='INVOICES'){         
          setisInvoice(true)

        }
        else if((invoiceData?.note?.applied_on)==='PRODUCTS'){
          setisInvoice(false)
        }

        // if (invoiceData.data.type === "PROFORMA") {
        //   setProforma(false);
        // }
      }
    } catch (error) {
      console.log(error);
      setInvoice({});
    }
  };

  const doc = new jsPDF();

  const handleDownloadInvoice = () => {
    if (auth.tenantDetails) {
      const inputElement = document.getElementById("invoice-slip-pdf");
      if (inputElement) {
        html2pdf(inputElement, {
          margin: 10,
          filename: "invoice.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
          .set()
          .then((pdf) => {
            const blob = pdf.output("blob");
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "output.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
          });
      } else {
        toast.error("order details not fixed yet");
      }
    }
  };

  return (
    <div className="invoice-container" style={{ padding: "0px" }}>
      <div className="upper-section">
        <div className="left-upper-section">
          <span
            style={{ fontWeight: "bold", marginTop: "5px", fontSize: "15px" }}
          >
            {invoice?.note?.tenant_details?.name.toUpperCase()}
          </span>
          <span>{invoice?.note?.tenant_details?.address}</span>
          <span>
            Phone :{" "}
            {invoice?.note?.tenant_details?.business_details?.contact_number}
          </span>
          <span style={{ marginTop: "5px" }}>
            E-Mail : {invoice?.note?.tenant_details?.business_details?.email}
          </span>
        </div>
        <div className="right-upper-section">
          <span
            style={{ marginTop: "5px", fontSize: "15px", fontWeight: "bold" }}
          >
            {invoice?.note?.entity_details?.title.toUpperCase()}
          </span>
          <p style={{ fontSize: "11px" }}>
            <span style={{ fontWeight: "bold", fontSize: "13px" }}>
              Address :{" "}
            </span>
            {invoice?.note?.entity_details?.address}
          </p>
          <p style={{ fontWeight: "bold" }}>
            D.L NO.{" "}
            <span style={{ fontSize: "12px" }}>
              {invoice?.note?.entity_details?.dl_number}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            GSTIN. :
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {invoice?.note?.entity_details?.gstin}
            </span>
          </p>
          <p style={{ fontSize: "13px", fontWeight: "bold" }}>
            PH NO.
            <span style={{ fontWeight: "lighter" }}>
              {" "}
              {invoice?.note?.entity_details?.phone_number}
            </span>
          </p>
        </div>
      </div>
      <div className="middle-section">
        <div
          className="middle-left-section"
          style={{ borderRight: "2px solid black" }}
        >
          <span style={{ display: "block" }}>
            GSTIN : {invoice?.note?.tenant_details?.business_details?.gst}
          </span>
          <span>
            DL NO :{" "}
            {
              invoice?.note?.tenant_details?.business_details
                ?.drug_license_number
            }
          </span>
        </div>
        <div
          className="middle-center-section"
          style={{ borderRight: "2px solid black" }}
        >
          {!isProforma && (
            <h1
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              PROFORMA INVOICE
            </h1>
          )}
          {isProforma && <h1 style={{ textAlign: "center", paddingRight:'30px'}}>{invoice?.note?.type} NOTE</h1>}
        </div>
        <div className="middle-right-section">
          <span style={{ display: "block" }}>
            Ref No : {invoice?.note?.reference_number}
          </span>
          <span>Date : {convertTimestamp(invoice?.note?.created_on)}</span>
        </div>
      </div>
      {!isInvoice? <div className="table-section">
        <div className="table-heading">
          <p>SN.</p>
          <p style={{ flex: "4" }}>PRODUCT_NAME</p>
          <p>PACK</p>
          {isProforma && <p>HSN</p>}
          <p style={{ flex: "1.5" }}>BATCH</p>
          <p>QTY</p>
          <p>MRP</p>
          <p>RATE</p>
          {isProforma && <p>GST</p>}
          {isDiscount && <p>Discount</p>}
          <p >AMOUNT</p>
        </div>
        <div className="table-body">
          {invoice?.items?.map((item, idx) => {
            if(item?.discount){
              setIsDiscount(true)
            }
            return (
              <div className="table-body-row" key={idx}>
                <p>{idx + 1}</p>
                <p style={{ flex: "4" }}>{item?.item_name}</p>
                <p>{Number(item?.quantity)}</p>
                {isProforma && <p>{item?.hsn_code}</p>}
                <p style={{ flex: "1.5" }}>
                  {item?.batch_number}
                </p>
                <p>{Number(item?.quantity)}</p>
                <p>{Number(item?.final_price)}</p>
                <p>{item?.final_price}</p>
                {isProforma && <p>{item?.invoice_details?.gst_applicable}</p>}
                {isDiscount && <p>{item?.discount}</p> }
                <p style={{ border: "none" }}>
                  {Number(item?.final_price) * Number(item?.quantity)}
                </p>
              </div>
            );
          })}
        </div>
      </div>:
      <div className="table-section">
      <div className="table-heading">
        <p>SN.</p>
        <p style={{  }}>Invoice Number</p>
        {/* <p>PACK</p>
        {isProforma && <p>HSN</p>}
        <p style={{ flex: "1.5" }}>BATCH</p>
        <p>QTY</p>
        <p>MRP</p>
        <p>RATE</p>
        {isProforma && <p>GST</p>} */}
        <p style={{ border: "none" }}>AMOUNT</p>
      </div>
      <div className="table-body">
        {invoice?.items?.map((item, idx) => {
          return (
            <div className="table-body-row" key={idx}>
              <p>{idx + 1}</p>
              <p>{Number(item?.invoice)}</p>

              <p style={{ border: "none" }}>
                {Number(item?.final_price) * Number(item?.quantity)}
              </p>
            </div>
          );
        })}
      </div>
    </div>
      }
      <div className="footer-section">
        <div className="footer-left-section">
          <div
            style={{
              paddingLeft: "10px",
              height: "4rem",
              borderBottom: "2px solid black",
            }}
          ></div>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <u style={{ fontWeight: "bold" }}>Terms & Conditions</u>
              <p>Goods once sold will not be taken back or exchanged.</p>
              <p>Bills not paid due date will attract 24% interest</p>
              <p>All disputes subject to Jurisdiction only.</p>
              <p>Prescribed Sales Tax declaration will be given</p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                BANK DETAILS: YES BANK(LUCKNOW)
              </p>
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                {" "}
                A/c No: 001863300007237,IFSC:YESB0000018{" "}
              </p>
              <p style={{ marginBottom: "5px" }}>
                Rs.{invoice?.note?.final_price}
                

              </p>
              <p>{(number2text(customCeil(invoice?.note?.final_price)))}</p>
              
            </div>
            <div
              style={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "2rem",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "1rem" }}>
                For {invoice?.note?.tenant_details?.name}
              </p>
              <p style={{ fontWeight: "bold" }}>Authorized Signatory</p>
            </div>
          </div>
        </div>
        <div className="footer-right-section">
          <div
            style={{
              flex: "3",
              borderBottom: "2px solid black",
              padding: "5px",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>SUB TOTAL</p>
              {isProforma && <p>SGST</p>}
              {isProforma && <p>CGST</p>}
              <p>Round Off</p>
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{invoice?.note?.sub_total}</p>
              {isProforma && <p>{invoice?.note?.sgst}</p>}
              {isProforma && <p>{invoice?.note?.cgst}</p>}
              <p>{invoice?.note?.final_price}</p>
            </div>
          </div>
          <div style={{ flex: "1" }}>
            <p style={{ fontWeight: "bold", fontSize: "15px", margin: "10px" }}>
              GRAND TOTAL.{" "}
            </p>
            <p
              style={{
                marginTop: "10px",
                marginRight: "10px",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              Rs. {customCeil(invoice?.note?.final_price)}
              
            </p>
            
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Notes;
