import React from 'react'
import '../Auth.scss';
import { Button, InputField } from '../../../Components';
import { findError } from '../../../Utils';
import Loader from '../../Loader';

export default function Otp({ controls, submitHandler, onChange, errors, showErr, sendOtp, loading }) {
    const { phone_number, otp } = controls;
    return (
        <div className="flex flex-col gap-15">
            <div/>

            <InputField 
                type='number' 
                placeholder='Mobile Number' 
                value={phone_number} 
                onChange={(e) => onChange(e, 'PHONE')} 
                disabled={true} 
                error={findError('phone_number', errors, showErr)} 
            />

            <InputField 
                type="number" 
                placeholder='OTP' 
                value={otp} 
                onChange={(e) => onChange(e, 'OTP')} 
                error={findError('otp', errors, showErr)} 
            />
            
            <span 
                className="resend-otp text-bold-lg" 
                onClick={() => sendOtp(true)}>
                    Resend OTP
            </span>

            <button
                type='button'
                className='btn-inactive btn-lg'
                onClick={submitHandler}
            >
                {
                    loading ? 
                    <Loader /> : 
                    'Verify Otp'
                }
            </button>
        </div>
    )
}
