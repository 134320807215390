// import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
// import Checkout from './Pages/Checkout/Checkout';
// import Comingsoon from './Pages/Comingsoon/Comingsoon'
import Feedback from './Pages/Feedback/Feedback';
import Feedbackthank from './Pages/Feedback/Feedbackthank';
import Allorder from './Pages/Allorder/Allorder'
import TermsCondition from './Pages/TermsCondition/TermsCondition'
import Stock from './Pages/StockAdd/Stock';
import AllRecentOrder from './Pages/AllRecentOrder/AllRecentOrder';
import {
  Home,
  Category,
  Checkout,
  OrderSuccess,
  Timer,
  EverythingDelivery,
  ActiveOrders,
  Favourite,
  AddStock,
  Invoice,
  Challan,
  PunchEorder,
  WastedProduct,
  Terms,
  Shippingpolicy,
  Privacypolicy,
  Aboutus,
  Contactus
} from './v1/Pages';
import Notes from './v1/Pages/Notes/Notes.js';
import { Navigation, Footer, Bottom, ProductCard, Auth } from './v1/Components';
import configureStore from './v1/Redux/store/configureStore';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './v1/Components/ScrollToTop/ScrollToTop';
import { AuthWrapper } from './v1/Wrapper/'
import Carditems from './v1/Components/cardItems/CardItems';
import TenantDashboard from './v1/Pages/TenantDashboard/TenantDashboard';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';

// import './App.scss'

import { AllProduct } from './v1/Components/ProductCategory/Products.js';
import { useEffect } from 'react';

export const { store } = configureStore();

function App() {
  const location = useLocation();

  // Condition to hide header on specific paths
  const hideHeaderPaths = ["/challan/:orderid"];

  const shouldShowHeader = !hideHeaderPaths.some((path) =>
    location.pathname.includes(path.replace(":orderid", ""))
  );

  const contextClass = {
    info: "toast-info",
    error: "toast-error",
    success: "toast-success",
  };

  return (
    <div className="AppRoot font-poppins relative overflow-x-hidden">
      <Provider store={store}>
        <Switch>
          <Route path='/dashboard' render={() => (<AuthWrapper><TenantDashboard /></AuthWrapper>)} />
          <Route path="/">

            <Navigation shouldShowHeader={shouldShowHeader}/>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path={'/allproducts'} component={AllProduct} />
              {/* <Route exact path="/comingsoon" component={Comingsoon} /> */}
              <Route path="/categories/:category" component={() => (<AuthWrapper><Category /></AuthWrapper>)} />
              <Route path="/categories" component={() => (<AuthWrapper><Category /></AuthWrapper>)} />
              <Route path="/everythingdelivery" component={() => (<AuthWrapper><EverythingDelivery /></AuthWrapper>)} />
              <Route path="/addstock/:id/" component={AddStock} />
              <Route exact path="/order-placed" component={OrderSuccess} />
              <Route path="/feedback/:orderid" component={Feedback} />
              <Route path="/feedback-sent" component={Feedbackthank} />
              <Route path="/invoice/:orderid" component={Invoice} />
              <Route path="/challan/:orderid" component={Challan} />
              <Route path="/notes/:orderid" component={Notes} />
              <Route path="/add/everythingorder/:id" component={PunchEorder} />
              <Route path="/recent/order" component={Allorder} />
              <Route path="/all/order" component={AllRecentOrder} />
              <Route path="/myorders" component={() => (<AuthWrapper><ActiveOrders /></AuthWrapper>)} />
              {/* <Route path="/terms" component={TermsCondition} /> */}
              {/* <Route path="/addstock" component={Stock} /> */}
              <Route path="/checkout" component={() => ( <AuthWrapper> <Checkout /> </AuthWrapper> )} />
              <Route path="/wastedproduct" component={WastedProduct} />
              <Route path="/wishlist" component={() => (<AuthWrapper><Favourite /></AuthWrapper>)} />
              {/* <Route path="/terms" component={Terms} /> */}
              {/* <Route path="/shipping-policy" component={Shippingpolicy} /> */}
              <Route path="/privacy-policy" component={Privacypolicy} />
              {/* added */}

              <Route path='/orderItems' render={() => <Carditems />} />
              {/* <Route path="/aboutus" component={Aboutus} />
                <Route path="/contactus" component={Contactus} /> */}
              <Route path="*" component={Home} />
            </Switch>
          <Footer />
          </Route>
        </Switch>

      </Provider>
      <ToastContainer
        bodyClassName={'toast-body'}
        autoClose={1500}
        closeOnClick
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnHover
        position="top-right"
        theme="dark"
        toastClassName={({ type }) => contextClass[type || 'default'] + ' toast-container'}
        limit={1}
      />
    </div>
  );
}

export default App;
