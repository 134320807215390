import React from 'react'
import PunchForm from './PunchForm'

export default function PunchEorder() {
    return (
        <>
            <div className="punche-order-container">
                <PunchForm />
            </div>
        </>
    )
}
