import React from "react";
import close from "./close.svg";
import "./CheckoutProduct.scss";
import AddToCartButton from "../../AddToCartButton";
import Tooltip from "../../ProductCard/Tooltip";
import crossIcon from '../../../Assets/Icons/cross-icon.png'

export default function CheckoutProduct({
    photo,
    product,
    description,
    quantity,
    price,
    final_price,
    removeItem,
    id,
    readyOnly,
    quantity_remaining,
    showStrikeThrough,
    discount,
    out_of_stock
}) {
    if (quantity_remaining < quantity) {
        out_of_stock = true
    }
    else if (quantity == quantity_remaining) {
        out_of_stock = false
    }
    return (

        <div className="checkout-product">

            {/* checkout product image  */}
            <div className={`img-div ${out_of_stock ? "fade-card" : ''}`}>
                {!readyOnly && (
                        <button onClick={() => removeItem()} style={{
                            outline : 'none',
                            border : 'none',
                            backgroundColor:'transparent',
                            height: '2rem',
                            aspectRatio : 1,
                            position : 'absolute',
                            top: 0, 
                            right: 0,
                        }}>
                            <img src={crossIcon} alt=""/>
                        </button>
                )}
                <img src={photo} alt="No Image" />
            </div>

            {/* checkout product details */}
            <div className="checkout-details">

                <div className={`checkout-product-details`}>

                    <div className={`${out_of_stock ? "fade-card" : ''}`}>

                        <div className="checkout-product-name">
                            {product}
                        </div>

                        <div className="checkout-product-description">{description}</div>

                        {quantity > quantity_remaining && quantity_remaining == 0 && out_of_stock ?
                            <p className="out-of-stock">Out Of Stock</p> : null
                        }
                        {quantity > quantity_remaining >= 1 && quantity_remaining != 0 && out_of_stock ?
                            <p className="out-of-stock">Only {quantity_remaining} item left!</p> : null
                        }

                    </div>

                    <div className="product-price">

                        <div className="">
                            <span>
                                <span className="ruppe">₹</span> 
                                {parseFloat(price) !== 0 ? parseFloat(price) : "pending"}
                            </span>
                            <span className="checkout-product-quantity">x{parseInt(quantity)}</span>
                        </div>

                        <p>=</p>

                        <div className="">
                            <span>
                                <span className="ruppe">₹</span>
                                {!parseFloat(final_price) ? "Pending" : parseFloat(price) * quantity}
                            </span>
                            <span className="checkout-product-quantity">x{parseInt(quantity)}</span>
                        </div>
                    </div>


                    {!readyOnly && (
                        <AddToCartButton
                            product={{
                                title: product,
                                price,
                                quantity,
                                description,
                                image: photo,
                                id,
                                quantity_remaining,
                            }}
                        />
                    )}
                </div>

                <div className="checkout-product-delete">
                    {/* {showStrikeThrough === true ?
                        <span className="product-price">
                            {parseFloat(price) * quantity !== parseFloat(final_price)
                                ?
                                <div className="strikeThrough">
                                    <span className="ruppe">₹</span>
                                    {parseFloat(price) * quantity}
                                </div>
                                : null
                            }
                        </span>
                        : null
                    } */}
                </div>
            </div>
        </div>
    );
}
