import React, { useState, useEffect } from "react";

import "./ProductCard.scss";

import { AddToCartButton } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { findFavouriteItem } from "../../Utils";
import { productAPI } from "../../Api";
import { toast } from "react-toastify";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

import defaultImage from "../../Assets/Images/default-image.png";

import Modal from "../Modal";
import Tooltip from "./Tooltip";

import starIconFilled from '../../Assets/Icons/star-icon.svg'
import starIconOutline from '../../Assets/Icons/star-icon-outline.svg'

const mapStateToProps = ({ favouriteProducts, auth }) => ({
  favouriteProducts,
  auth,
});

const ProductCard = (props) => {

  const { favouriteProducts, auth } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let price = parseFloat(props.price);
  const { products = [] } = favouriteProducts;
  const { isLoggedIn } = auth;

  const updateFavouriteProduct = async (value) => {
    try {
      const payload = {
        product_id: props.id,
      };
      if (value) {
        // remove from favourite
        const res = await productAPI.deleteFavouriteProduct(payload);
        toast.success("Product is removed from your wishlist");
        fetchFavouriteProducts();
      } else {
        // add in favourite
        const res = await productAPI.addFavouriteProduct(payload);
        toast.success("Product is added in your wishlist");
        fetchFavouriteProducts();
      }
    } catch (error) {
      toast.success("Error while updating wishlist");
    }
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  const isFavourite = findFavouriteItem(props.id, products);

  const [viewProduct, setviewProduct] = useState({
    image: "",
    title: "",
    price: "",
    description: "",
  })

  const [modal, setModal] = useState(false);
  const outOfStock = props.quantity_remaining <= 0;


  const newArray = Array.from({ length: 5 }, () => 0);
  return (
    <div
      className={
        outOfStock && !modal ?
          "product-wrapper fade-card" :
          "product-wrapper"
      }
    >

      {/* modal displays more information about the product */}

      <Modal 
        show={modal} 
        onClose={() => setModal(false)}
      >
        <div className="modal-image-viwer">
          <div className="cross" onClick={() => setModal(false)}>
            ✕
          </div>
          <img src={viewProduct.image} alt="" />
          <div className="modal-image-text">
            <span className="title" >{viewProduct.title}</span>
            <span className="desc">{viewProduct.description}</span>
            <span className="price">₹{parseFloat(viewProduct.price)}</span>
          </div>
        </div>
      </Modal>

      <div className='product-card-container'>
        <img
          className="product-card-img"
          src={props.image ? props.image : defaultImage}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultImage
          }}
          onClick={() => {
            setModal(true);
            setviewProduct({
              image: props.image,
              title: props.title,
              price: props.price,
              description: props.description
            })
          }}
        />

        <div className='product-card-title-rating'>

          <div className='product-card-title'>
            {
              props.title?.substring(0,60)
            }
          </div>
          {/* <div className='product-card-rating'>
            <div>
              {
                newArray.map((value, index) => {
                  return (
                    index <= (props.rating - 1) ?
                      <img src={starIconFilled} /> :
                      <img src={starIconOutline} />
                  )
                })
              }
            </div>
            <span>{props.rating}.0 rating</span>
          </div> */}

          <div className="product-card-price">
            
              {
                props.oldprice ?
                  (
                    <p className="strikethrough-price">
                      <span className="ruppe">₹</span> {parseFloat(props.oldprice).toFixed(2)}
                    </p>
                  ) :
                  null
              }
              <p>
                <span className="ruppe">₹</span> {price}
              </p>
          </div>
        </div>

        {
          outOfStock ?
            (
              <p className="out-of-stock">Out Of Stock</p>
            ) :
            (
              <AddToCartButton product={props}/>
            )
        }
      </div>

      {

        isLoggedIn &&
        (
          <div className="add-to-favorite-icon">
            <div
              className={!isFavourite ? "heart-grey" : "heart-red"}
              onClick={() => updateFavouriteProduct(isFavourite)}
            ></div>
          </div>
        )

      }

      {/* <div className="product-mid-text">

        <Tooltip content={props.title}>
          <h3>{props.title.slice(0, 18)}{props.title.length > 18 && "..."}</h3>
        </Tooltip>
        <p>{props.description}</p>

      </div > */}
    </div >
  );
};

export default ProductCard;

ProductCard.defaultProps = {
  image: defaultImage,
};
