// import { Button } from "bootstrap";
import InputField from "../InputField"
import { useSelector } from "react-redux";
import { useState } from "react";
import './Callback.scss'
import { callBackRequest, raiseTicket } from "../../Api/contactApi";
import { toast } from "react-toastify";

const mapStateToProps = ({ cart, auth, payment }) => ({
    auth,
  });
export const Callback = ({toggleContactFormModal})=>{
    const {  auth } = useSelector(mapStateToProps);
  const { isLoggedIn, user = {} } = auth;
  const [phone_number, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [formData, setFormData] = useState({
    name : '',
    phone_number : '',
    email : '',
    description : '',
    issue : '',
  })

  const changeHandler = (e)=>{
    const {name, value} = e.target
    setFormData((prev)=>{
        return {
            ...prev,
            [name]: value
        }
    })
  }
  const submitHandler = async (data)=>{
    try{
        formData["tenant"] = auth?.tenantDetails?.id
        const response = await raiseTicket(formData,1)
        toast.success("We will get back to you soon")
        toggleContactFormModal()

    }catch(err){
        // handle error case
        
    }
    
  }
    return(
        <div className="flex flex-col gap-10">
            <div 
              className="text-xxlarge medium-bold"
              style={{
                marginBottom : '1rem'
              }}
            >
              Request for callback
            </div>
            {
                isLoggedIn?(null):(
                <>
                  <InputField
                    type='text'
                    placeholder='Name'
                    name="customer_name"
                    onChange={changeHandler}
                    value={formData.customer_name}
                    label={'Enter your Name'}
                    required={true}
                  
                    // value={phone_number}
                    // onChange={(e) => onChange(e, 'PHONE')}
                    // error={findError('phone_number', errors, showErr)}
                  />
                  
                  <InputField
                    type='number'
                    name="phone_number"
                    placeholder='Mobile Number'
                    label={'Enter your mobile number'}
                    onChange={changeHandler}
                    value={formData.phone_number}
                    required={true}
                  />
                </>)
            }

            <InputField
              type='email'
              placeholder='Email'
              label={'Enter your email'}
              name="email"
              onChange={changeHandler}
              value={formData.email}
              required={false}
              // onChange={(e) => onChange(e, 'PHONE')}
              // error={findError('phone_number', errors, showErr)}
            />

            <InputField
            type='text'
            placeholder='Issue'
            label={'Enter your issue'}
            name="ticket_type"
            onChange={changeHandler}
            value={formData.ticket_type}
            required={true}
            // onChange={(e) => onChange(e, 'PHONE')}
            // error={findError('phone_number', errors, showErr)}
            />

            <InputField
            type='email'
            placeholder='Description'
            label={'Enter the description'}
            name="description"
            onChange={changeHandler}
            value={formData.description}
            required={true}
            // onChange={(e) => onChange(e, 'PHONE')}
            // error={findError('phone_number', errors, showErr)}
            />

            <div className="flex flex-row justify-center items-center gap-10">
                <button
                  onClick={toggleContactFormModal}
                  className="btn-none btn-inactive btn-md"    
                >
                  Back
                </button>
                <button
                  className="btn-none btn-active btn-md"
                  onClick={submitHandler}
                >
                  Send
                </button>
            </div>
            
        </div>
    )
}