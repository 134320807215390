import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import Auth from "../Auth/Auth";
import "./Navbar.scss";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import logoImg from "../../Assets/Images/navbar/new_logo.svg";
import searchIcon from "../../Assets/Images/navbar/search-icon.svg";
// import logoImg from "../../Assets/Images/navbar/blue_logo.png";
// import searchIcon from "../../Assets/Images/navbar/search.svg";
import everythingIcon from "../../Assets/Images/navbar/blue-icon.svg";
import favouriteIcon from "../../Assets/Images/navbar/favourites.svg";
import loginIcon from "../../Assets/Images/navbar/login.svg";

import cartIcon from "../../Assets/Icons/cart-icon.svg";
import userIcon from "../../Assets/Icons/user-icon.svg";

import basketIcon from "../../Assets/Images/navbar/basket.svg";
import walletIcon from "../../Assets/Images/navbar/wallet.png";

import arrowDown from "../../Assets/Images/navbar/arrow-down.svg";
import { GiHamburgerMenu } from "react-icons/gi";

import { toast } from "react-toastify";
// import useOutsideAlerter from '../../Hooks/useOutsideAlerter'
import { debounce, formatName } from "../../Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { cartAPI, authAPI } from "../../Api";

import Modal from "../Modal2/Modal.js";
import { FaHamburger } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";

const mapStateToProps = ({ cart, auth, payment }) => ({
  cart,
  auth,
  payment,
});
const Navbar = (props) => {
  const [showheader, setShowHeader] = useState(
    props.shouldShowHeader.shouldShowHeader
  );
  const { cart, auth, payment } = useSelector(mapStateToProps);
  const { showLoginPopup = false, isLoggedIn, userDetails = {} } = auth;

  const { walletBalance = 0, isWallet = false } = payment;
  const [searchterm, setsearchterm] = useState(""); //text within searchbox
  const [showsearch, setshowsearch] = useState("");
  const [walletData, setWalletData] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]); //company Details

  useEffect(() => {
    props.search(searchterm);
  }, [searchterm]);

  const logOut = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
    setOpenProfile(false);
  };

  const walletFetcher = async () => {
    try {
      let res = await cartAPI.getWalletData();
      dispatch(
        actionsCreator.UPDATE_WALLET({
          walletBalance: res.data.data.balance,
          isWallet: true,
        })
      );
    } catch (error) {
      dispatch(actionsCreator.UPDATE_WALLET({ isWallet: false }));
      console.log(error);
    }
  };

  // const companyFetcher = async () => {
  //   try {
  //     let res = await authAPI.company();
  //     setCompany(res.data);
  //   } catch (error) {
  //     console.log("ERROR in Company API", error)
  //   }
  // };

  useEffect(() => {
    if (isLoggedIn) walletFetcher();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   companyFetcher();
  // }, [])

  const { name = "", email = "" } = userDetails || {};
  const formattedName = formatName(name);
  const { final_item = 0, final_price = 0 } = cart || {};

  function useOutsideAlerter(ref, key, loginRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            key === "login" &&
            loginRef.current &&
            !loginRef.current.contains(event.target)
          )
            props.removeLoginPopup();
          else setOpenProfile(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const loginStateSetter = () => {
    dispatch(actionsCreator.SHOW_LOGIN());
  };

  const clickOutsideProfile = useRef();
  const clickOutsideLogin = useRef();
  const loginMenuBtn = useRef();

  useOutsideAlerter(clickOutsideProfile, "profile");
  useOutsideAlerter(clickOutsideLogin, "login", loginMenuBtn);

  const [showHamburgerNav, setShowHamburgerNav] = useState(false);
  const handleModalDisplay = () => {
    setShowHamburgerNav((prev) => !prev);
  };

  useEffect(() => {
    setShowLoginModal(showLoginPopup);
  }, [showLoginPopup]);

  const [showloginModal, setShowLoginModal] = useState(showLoginPopup);

  const removeLoginPopup = () => {
    dispatch(actionsCreator.SET_LOGIN({ showLoginPopup: false }));
    setShowLoginModal(false);
    // setsearchResults([]);
  };

  return (
    <nav className="navbar h-32 w-full">
      {showHamburgerNav && (
        <Modal closeModal={handleModalDisplay} backgroundColor={"white"}>
          <ul className="navbar-links h-full w-full flex-column items-center">
            <li>
              <Link>How It works</Link>
            </li>
            <li>
              <Link>Medicals</Link>
            </li>
            <li>
              <Link>Partners</Link>
            </li>
            <li>
              <Link>Services</Link>
            </li>
          </ul>

          {isLoggedIn ? (
            <></>
          ) : (
            <div className="flex-row items-center justify-center m-6">
              <button
                onClick={loginStateSetter}
                className="btn-none btn-active btn-md"
              >
                <span className="">Sign Up</span>
              </button>
            </div>
          )}
        </Modal>
      )}

      <div className="navbar-items w-full">
        <Link to="/">
          <div className="flex flex-row items-center gap-10">
            <img
              style={{
                height: "2.5rem",
                aspectRatio: "1",
                objectFit: "contain",
              }}
              src={auth.tenantDetails?.logo}
            />
            <span className="text-uppercase text-xxlarge medium-bold">
              {auth.tenantDetails?.title || "Health Care"}
            </span>
          </div>
        </Link>

        {showheader && (
          <ul className="navbar-links ul-lg">
            <li>
              <Link>How It works</Link>
            </li>
            <li>
              <Link>Medicals</Link>
            </li>
            <li>
              <Link>Partners</Link>
            </li>
            <li>
              <Link>Services</Link>
            </li>
          </ul>
        )}

        {isLoggedIn ? (
          <div className="flex-row items-center justify-center m-6">
            {showheader && (
              <button onClick={logOut} className="btn-none btn-active btn-desk">
                Logout
              </button>
            )}
          </div>
        ) : (
          <div className="flex-row items-center justify-center m-6">
            {showheader && (
              <button
                onClick={loginStateSetter}
                className="btn-none btn-active btn-md block-lg-only"
              >
                Sign Up
              </button>
            )}
          </div>
        )}

        {showLoginPopup && (
          <Modal closeModal={() => dispatch(actionsCreator.SHOW_LOGIN())}>
            <div
              style={{
                height: "100%",
                width: "100%",
              }}
              className="flex-row justify-center items-center"
            >
              <Auth />
            </div>
          </Modal>
        )}

        <button
          onClick={() => {
            setShowHamburgerNav(true);
          }}
          className="btn-none hamburger"
        >
          <RxHamburgerMenu fontSize={"2.5rem"} color={"black"} />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;

Navbar.defaultProps = {
  location: "Whitefield",
  total_cost: 0,
};
