import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";
import doctorVideo from "../../Assets/Images/doctor-video.png";

import { FaPlayCircle } from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import { Callback } from "../../Components/Callback/Callback";
import axios from "axios";
import { getBaseUrl } from "../../Lib/NetworkHandler";
import { fetchClients } from "../../Api/tenantAPi";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

const Home = () => {
  const dispatch = useDispatch();

  const {
    auth,
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);

  const { isLoggedIn } = auth;
  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);
  const [showContactModal, setShowContactModal] = useState(false)

  const toggleContactFormModal = ()=>{
    setShowContactModal(!showContactModal)
  }
 

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };
  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  const [client, setClient] = useState([])
  useEffect(() => {
    const fetchClientData = async () => {
      const res = await fetchClients();
      console.log(res.data,"clientele data");
      setClient(res.data);
    }

    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    fetchClientData();
  }, [isLoggedIn]);

  return (
    <div className="home">
      <div className="banner1 flex-column items-center justify-center gap-15">
        <h1 className="text-lg text-white text-center">Revolutionizing healthcare</h1>
        <h5>Implants Made Accessible.</h5>
        <div>
          <button 
            className="btn-none btn-active btn-lg"
            onClick={toggleContactFormModal}
            style={{
              padding : '1rem 2.5rem'
            }}
          >
            Request a callback
          </button>
        </div>
      </div>
      <Modal  show={showContactModal}
      style={{
        minHeight: "100vh",
        minWidth: "90vw",
      }}
      >
        <Callback 
        toggleContactFormModal={toggleContactFormModal}
        />
      </Modal>

      {/* <div className="banner2">
        <button 
          className="btn-active btn-lg"
          onClick={toggleContactFormModal}
        >
          Request a callback
        </button>
      </div> */}

      <Modal  
        show={showContactModal}
        // style={{
        //   width : '100%',
        //   height : '80vh'
        // }}
      >
        <Callback 
          toggleContactFormModal={toggleContactFormModal}
        />
      </Modal>

      <div className="flex flex-col gap-5">
        <div className="text-xlarge semi-bold text-first">
          CONNECT, CONSULT, AND CARE
        </div>
        <div 
          className="bold text-first nowrap"
          style={{
            fontSize : '2.8rem'
          }}
        >
          Implants made easy
          <img
            src={medkit}
            alt={"Imgnotfound"}
            style={{
              height: "4rem",
              aspectRatio: "1",
              objectFit: "contain",
              margin : '0 5px'
            }}
          />
        </div>

        <div className="banner2-bottom">

          <div className="details-and-girlmedkit flex-1">
            <img
              src={girlwithmeds}
              alt="No image Found"
              style={{
                width: "20rem",
                aspectRatio : '2/3',
                objectFit: "contain",
              }}
            />
          </div>

          <div className="three-boxes flex-1">
            <div 
              className="flex-column gap-10 flex-1"
            >

              <div 
                className="blue-bg flex-column"
              >
                <p className="text-large text-bold-md text-white">
                  Our collaborations
                  <br />
                </p>

                <div className="flex-row w-full justify-between">
                  <div
                    className="flex-column items-center justify-center gap-10"
                    style={{
                      backgroundColor: "white",
                      width: "6rem",
                      aspectRatio: "1",
                      borderRadius: "10px",
                    }}
                  >
                    <strong>
                      100+
                      <sub>doctors</sub>
                    </strong>

                    <strong
                      style={{
                        fontSize: "0.6rem",
                        color: "#777",
                      }}
                    >
                    </strong>
                  </div>

                  <div
                    className="flex-column items-center justify-center gap-10"
                    style={{
                      backgroundColor: "white",
                      width: "6rem",
                      aspectRatio: "1",
                      borderRadius: "10px",
                    }}
                  >
                    <strong>49</strong>

                    <strong
                      style={{
                        fontSize: "0.6rem",
                        color: "#777",
                      }}
                    >
                      Hospitals
                    </strong>
                  </div>
                </div>
              </div>

              <div 
                className="green-bg flex-column justify-between"
                style={{
                  position : 'relative'
                }}
              >
                <img 
                  style={{
                    height : '12rem',
                    aspectRatio : '1',
                    objectFit : 'contain',
                    position : 'absolute',
                    bottom : '10%',
                    right : '10%'
                  }}
                  src={tablets}
                />

                <h2 className="text-white">1000+</h2>
                <h1 className="text-white">Surgeries</h1>

                <button
                  className="btn-none"
                  style={{
                    padding: "8px 10px",
                    color: "black",
                    fontWeight: "400",
                    backgroundColor: "white",
                    borderRadius: "40px",
                    width: "8rem",
                  }}
                >
                  Services
                </button>
              </div>
            </div>

            <div 
              className="dark-blue-bg flex-column justify-between flex-1 gap-10"
              style={{
                position : 'relative'
              }}
            >

                <div className="flex flex-row justify-between gap-10 w-full">
                </div>

                <img
                  src={doctorVideo}
                  style={{
                    width : '100%',
                    objectFit : 'contain',
                  }}
                />
            </div>
          </div>
        </div>
      </div>

      <div 
        className="text-center"
        style={{
          marginTop : '4rem', 
          marginBottom : '1rem',
        }}
      > 
        <p
         className="text-xxlarge semi-bold text-first"
         style={{
          marginBottom : '1rem',
        }}
        >Our Happy Clients
        </p>

        <ul className="responsive-cards-3-1 ul-style-none">
          {
            client?.length!==0 && client?.map((item) => {
              return (
                <li className="flex flex-col gap-10">
                  <img
                    src={item.image}
                    style={{
                      width : '100%',
                      aspectRatio : '1',
                      border : '1px solid #f2f2f2',
                      borderRadius : '5px'
                    }}
                  />
                  <span className="text-medium text-first">
                    {
                      item.company_name
                    }
                  </span>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  );
};

export default Home;
