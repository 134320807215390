import React from 'react'
import './Footer.css'

export default function Footer() {
    return (       
        <div className="container pt-3">
            <div>
                
                <p className="text-center footer_text">
                    &copy; Phurti - Instant Groceries 2021 
                </p>
            </div>
        </div>
    )
}
